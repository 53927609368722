import React from 'react';
import logo from './logo.svg';
import styles from './App.module.css';
import {FortuneTeller, Tile} from 'axon-component-library';

const fortunes = {
  A: [
    "Your tendency to over-analyze could get you bogged down this week!",
    "Those meetings really could have all been e-mails",
    "Extensive analysis indicates you should not rely on a fortune teller for advice"
  ],
  B: [
    "This week you'll find new thrills...if you're willing to let yourself think outside the box.",
    "Success is found by starting any statement with “as history tells us” or “as Benjamin Franklin said…”"
  ],
  C: [
    "Someone special you've been meaning to get in touch with will appreciate an unexpected video call from you today.",
    "You will soon be invited to a party with all of your favorite people on earth. Unfortunately, it will be on Zoom."
  ],
  D: [
    "A brilliant new idea is on the horizon...if you can discipline yourself long enough to sketch out a full, detailed plan.",
    "Stop procrastinating… starting tomorrow",
    "Luck is the residue of design"
  ]
}

export default class AprilFools extends React.Component {

  constructor(){
    super();
    this.state = {
      key: 1,
    };
  };

  _updateKey(key){
    this.setState({key: key+1, started: false, done: false});
  }

  render() {

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.outerWrapper}>
            <div className={styles.tileWrapper}>
              <Tile maxWidth={600}>
                <br/><br/>
                {!this.state.started && (<p className={styles.instructions}><b>Click a quadrant</b></p>)}
                {this.state.started && (<p className={styles.instructions}><b>Click an inside flap</b></p>)}
                <div style={{margin: "auto"}}>
                  <FortuneTeller
                    started={()=> setTimeout(()=> this.setState({started: true}), 1000)}
                    done={()=> this.setState({done: true})}
                    maxWidth={300}
                    key={this.state.key}
                    fortunes={fortunes} />
                </div>
                <button onClick={() => this._updateKey(this.state.key)}>Reset & Play Again</button>
                <br/><br/>
                {
                  this.state.done && (
                    <p>
                      <b>Download a printable version
                        <a href="https://www.thinkherrmann.com/hubfs/HerrmannFortuneTeller.pdf"
                           target="_blank"
                           style={{textDecoration: "underline"}}> here
                        </a>.
                      </b>
                    </p>
                  )
                }
              </Tile>
            </div>
          </div>
      </div>
      </div>
    );
  }
}

